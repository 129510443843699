const isBaseline = (ref: string) => {
    switch(ref){
        case 'clubs':
        case 'sdm':
        case 'usor':
        case 'cmplus':
        case 'bfaem':
        case 'myschl':
        case 'pportal':
        case 'os':
        case 'corphome':
        case 'bw':
        case 'beta':
        case 'edu':
        case 'storyvoice':
        case 'sdc':
        case 'literacy':
        case 'face':
        case 'lp':
        case 'nys':
        case 'cos':
        case 'rif':
        case 'ror':
        case 'tso':
        case 'tsocheckout':
        case 'tsoprntquote':
        case 'sso':
        case 'ssocheckout':
        case 'ssocoreg':
        case 'ssocosignin':
        case 'ofesso':
            return false;
        default:
            return true;
    }
}

export default isBaseline;